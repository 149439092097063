import { ConfigProvider, Typography } from "antd";
import type { TitleProps } from "antd/es/typography/Title";
import type { FC } from "react";

import seedtime from "theme/seedtime";

type Props = TitleProps & {
  textAlign?: "left" | "center" | "right" | "justify";
};

const Title: FC<Props> = ({ textAlign, ...props }) => (
  <ConfigProvider
    theme={{
      token: {
        fontFamily: seedtime["heading-font"],
        fontWeightStrong: 600
      }
    }}
  >
    <Typography.Title
      {...props}
      style={{
        ...props.style,
        textAlign
      }}
    />
  </ConfigProvider>
);

export default Title;
