import type { TaskType } from "types";
import { TaskTypes } from "types";

const TASK_NAMES: Record<TaskType | "%future added value", string> = {
  [TaskTypes.DirectSeeding]: "Direct Seed",
  [TaskTypes.IndoorSeeding]: "Seeding",
  [TaskTypes.BedPreparation]: "Bed Preparation",
  [TaskTypes.Transplanting]: "Transplanting",
  [TaskTypes.Cultivating]: "Cultivating",
  [TaskTypes.Harvesting]: "Harvesting",
  [TaskTypes.StaleSeedBed]: "Stale Seed Bed",
  [TaskTypes.Custom]: "Custom",
  "%future added value": "Unknown task type"
};

type WithFutureTaskType = TaskType | "%future added value";

const humanizeTaskType = (taskType: WithFutureTaskType): string =>
  TASK_NAMES[taskType] || TASK_NAMES["%future added value"];

type HumanizeTaskProps = {
  name?: string | null;
  taskType: WithFutureTaskType;
};

export const humanizeTask = (task: HumanizeTaskProps) =>
  task.name ?? humanizeTaskType(task.taskType);

export default humanizeTaskType;
