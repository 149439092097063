import { useState } from "react";
import { func, string } from "prop-types";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";

import Modal from "components/core/Modal";
import { useAntdTheme } from "theme/antd";

const TaskMoveWarning = ({ warning, moveTask, cancelMove }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
    ReactDOM.unmountComponentAtNode(document.getElementById("task-modal"));
  };

  const handleCancel = () => {
    cancelMove();
    closeModal();
  };

  const handleOk = () => {
    moveTask();
    closeModal();
  };

  return (
    <ConfigProvider prefixCls="ant5" theme={useAntdTheme()}>
      <Modal
        layout="compact"
        title="Warning"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <div dangerouslySetInnerHTML={{ __html: warning }} />
      </Modal>
    </ConfigProvider>
  );
};

TaskMoveWarning.propTypes = {
  cancelMove: func.isRequired,
  moveTask: func.isRequired,
  warning: string.isRequired
};

export default TaskMoveWarning;
