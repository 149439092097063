import { Typography } from "antd";
import type { TextProps } from "antd/es/typography/Text";
import type { FC } from "react";

type Props = TextProps & {
  textAlign?: "left" | "center" | "right" | "justify";
  variant?: "body" | "caption";
};

const VARIANT_SIZE_MAP = {
  body: 16,
  caption: 13
};

const Text: FC<Props> = ({ variant = "body", textAlign, ...props }) => (
  <Typography.Text
    style={{
      ...props.style,
      fontSize: VARIANT_SIZE_MAP[variant],
      textAlign
    }}
    {...props}
  />
);

export default Text;
