// this file contains handlers for FullCalendar callbacks
import Rails from "@rails/ujs";
import dayjs from "dayjs";
import { createRoot } from "react-dom/client";

import * as api from "api";

import TaskMoveWarning from "../components/TaskMoveWarning";

import { removeAllTooltips } from "./calendar_event_tooltip";

const reloadCalendarViews = () => {
  window.State.markDirty();
  window.State.reloadAllCalendarViews();
};

const moveEvent = ({ delta, event, ...params }) => {
  const endDelta = delta ? delta : params.endDelta;
  const startDelta = delta ? delta : params.startDelta;

  const movingArguments =
    endDelta && startDelta
      ? {
          endDelta: endDelta.days,
          startDelta: startDelta.days
        }
      : {
          startDate: dayjs(event.start).format("DD/MM/YYYY"),
          endDate: dayjs(event.end).subtract(1, "day").format("DD/MM/YYYY")
        };

  const moveTask = () => {
    api
      .moveTask(event.extendedProps.task_id, {
        ...movingArguments,
        isMove: params.isMove
      })
      .then(reloadCalendarViews);
  };

  const cancelMove = () => {
    reloadCalendarViews();
  };

  return api
    .moveWarningTask(event.extendedProps.task_id, {
      ...movingArguments,
      isMove: params.isMove
    })
    .then(({ warning }) => {
      if (warning) {
        const root = createRoot(document.getElementById("task-modal"));

        root.render(
          <TaskMoveWarning
            warning={warning}
            moveTask={moveTask}
            cancelMove={cancelMove}
          />
        );
      } else {
        moveTask();
      }
    });
};

// shows info modal when clicking on planting events
export function onCalEventClick(info) {
  info.jsEvent.preventDefault();

  if (info.el.dataset.loading !== "true") {
    info.el.dataset.loading = true;

    Rails.ajax({
      url: `/task_notes/${info.event.extendedProps.task_id}.js`,
      type: "GET",
      complete: () => {
        info.el.dataset.loading = false;
      }
    });
  }
}

// shifts crop schedule for a planting
export function onCalEventDrop({ event, delta }) {
  // this is somewhat of a hack... when drag+dropping a calendar event, sometimes the tooltip doesn't disappear...
  // this will ensure all tooltips are removed at drop time
  removeAllTooltips();

  moveEvent({ delta, event, isMove: true });
}

export function onEventReceive({ event }) {
  removeAllTooltips();

  const lastDraggedEvent = window.State.getLastDraggedEvent();

  if (lastDraggedEvent.instanceId === event._instance.instanceId) {
    const delta = dayjs(dayjs(event.start)).diff(lastDraggedEvent.start, "day");

    moveEvent({ event, delta: { days: delta }, isMove: true });
    event.remove();
  }
}

export function onEventResize({ event, endDelta, startDelta }) {
  removeAllTooltips();
  moveEvent({ event, endDelta, startDelta });
}
