import type { FC } from "react";
import { styled } from "@linaria/react";
import { Flex } from "antd";

import { ReactComponent as ErrorIcon } from "images/error-broccoli.svg";

import { Paragraph, Title } from "../Typography";

const Container = styled(Flex)`
  height: 100%;
`;

const ErrorFallback: FC = () => (
  <Container>
    <Flex vertical align="center" justify="center" flex={1}>
      <ErrorIcon />
      <Title level={2}>
        {
          "We've encountered an issue. Please try refreshing the screen. If the problem persists, please contact our support team."
        }
      </Title>
      <Paragraph>
        {
          "We're working to fix the issue. Please try again later or contact support if the problem persists."
        }
      </Paragraph>
    </Flex>
  </Container>
);

export default ErrorFallback;
