import type { TaskFilter } from "types";
import { TaskTypes } from "types";

const DEFAULT_VALUES = Object.values(TaskTypes).reduce(
  (acc, type) => ({ ...acc, [type]: true }),
  {}
);

export const parseTaskFilter = (value: string | null): TaskFilter => ({
  ...DEFAULT_VALUES,
  ...(value ? JSON.parse(value) : {})
});

export const serializeTaskFilter = (taskFilter: TaskFilter): string =>
  JSON.stringify(taskFilter);

export const countActiveFilters = (taskFilter: TaskFilter): number =>
  Object.entries(taskFilter).reduce((acc, [key, filterValue]) => {
    if (TaskTypes.StaleSeedBed === key) {
      return acc;
    }

    return acc + (filterValue ? 1 : 0);
  }, 0);
