export enum TaskTypes {
  DirectSeeding = 'direct_seeding',
  IndoorSeeding = 'indoor_seeding',
  BedPreparation = 'bed_preparation',
  Transplanting = 'transplanting',
  Cultivating = 'cultivating',
  Harvesting = 'harvesting',
  StaleSeedBed = 'stale_seed_bed',
  Custom = 'custom',
}

export type TaskType = `${TaskTypes}`
