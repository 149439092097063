import { humanizeTask } from "helpers/humanizeTaskType";

const inFrostZone = (day, month, frost) => {
  if (frost.start_month < frost.end_month) {
    if (month > frost.start_month && month < frost.end_month) {
      return true;
    }
  }

  if (frost.start_month > frost.end_month) {
    if (month > frost.start_month || month < frost.end_month) {
      return true;
    }
  }

  if (frost.start_month == frost.end_month) {
    if (day >= frost.start_day && day <= frost.end_day && month == frost.start_month) {
      return true;
    } else {
      return false;
    }
  }

  if (month == frost.start_month && day >= frost.start_day) {
    return true;
  }

  if (month == frost.end_month && day <= frost.end_day) {
    return true;
  }

  return false;
};

export const getFrostClass = (date, averageFrost, hardFrost) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const isAverageFrost = inFrostZone(day, month, averageFrost);
  const isHardFrost = inFrostZone(day, month, hardFrost);

  if (isHardFrost) return "hard-frost";
  if (isAverageFrost) return "average-frost";
};

export const updateCalendarSizeOnPrint = () => {
  const mql = window.matchMedia("print");

  mql.onchange = (e) => {
    if (e.matches) {
      const calendars = Array.from(document.getElementsByClassName("calendar"));

      calendars.forEach((el) => {
        if (el.year?.calendar) {
          el.year.calendar.updateSize();
        }
        if (el.month?.calendar) {
          el.month.calendar.updateSize();
        }
      });
    }
  };
};

export const onYearMonthChange = (callback) => {
  document.addEventListener("calendarYearMonthChanged", callback, false);
};

export const removeYearMonthChangeListener = (callback) => {
  document.removeEventListener("calendarYearMonthChanged", callback);
};

export const triggerYearMonthChange = ({ yearMonth, monthsNumber }) => {
  const event = new CustomEvent("calendarYearMonthChanged", {
    detail: { yearMonth, monthsNumber }
  });
  document.dispatchEvent(event);
};

export const eventTitle = (event) => `${humanizeTask(event)} ${event.resourceTitle}`;
